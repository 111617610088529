import React from 'react';
import normalize from './style/normalize';
import font from './style/font';
import base from './style/base';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = () => {
  const Style = createGlobalStyle`
    ${font}
    ${normalize}
    ${base}
  `;

  return <Style />;
}

export default GlobalStyle;