import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"

const Root = styled.div`
  transition: all 0.2s ease-in-out;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background: ${({ showNavbarBackground }) =>
    showNavbarBackground ? "#fff" : "none"};
  box-shadow: ${({ showNavbarBackground }) =>
    showNavbarBackground ? "0 2px 28px 0 rgba(0, 0, 0, 0.06)" : "none"};

  a:hover {
    text-decoration: none;
  }

  @media (max-width: ${breakpoints.large}px) {
    position: sticky;
    top: 0;
  }
  @media (min-width: ${breakpoints.large}px) {
    padding: 10px 0 5px 0;
  }
`

const Header = styled.header`
  height: 90px;

  width: 100%;

  @media (max-width: ${breakpoints.large}px) {
    background: #fff;
  }
`
const NavbarConatianer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  @media (max-width: ${breakpoints.large}px) {
    justify-content: space-between;
    margin: 0;
    max-width: 100%;
  }

  & > a {
    flex-basis: 13%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 1rem;

    @media (max-width: ${breakpoints.large}px) {
      flex-basis: 25%;
      margin-right: auto;
    }
    @media (max-width: ${breakpoints.medium}px) {
      flex-basis: 30%;
      margin-right: auto;
    }
    @media (max-width: ${breakpoints.small}px) {
      flex-basis: 32%;
      margin-right: auto;
    }
  }
`
const NavbarLinks = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @supports (justify-content: space-evenly) {
    justify-content: space-evenly;
  }

  li {
    list-style: none;
    padding-top: 20px;

    & + li {
      margin-right: 2rem;
    }
  }
  a {
    color: #6f8ba4;
    height: 40px;
    line-height: 40px;
    letter-spacing: 1px;
    font-size: 1.2rem;
    font-weight: 700;

    &:hover {
      color: #6f8ba4;
    }
    @media (max-width: ${breakpoints.large}px) {
      font-size: 0.9;
    }
  }

  @media (max-width: ${breakpoints.large}px) {
    padding-top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    padding-right: 20px;
    padding-top: 0;
    align-items: center;
    border-bottom: 1px solid rgba(211, 211, 211, 0.2);
    a {
      width: 100%;
    }
    &:hover {
      background: #f5f5f5;
    }
    li {
      margin-right: 1rem;
    }
  }

  @media (max-width: ${breakpoints.large}px) {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background: white;
    transition: all 0s ease 0s;
    flex-direction: column;
    height: auto;
    &.unshow {
      display: none;
    }
  }
`

const HamburgerMenuButton = styled.div`
  cursor: pointer;
  position: relative;
  right: 15px;
  flex-basis: 32px;
  height: 20px;
  display: none;
  order: -1;

  @media (max-width: ${breakpoints.large}px) {
    display: block;
  }
  @media (max-width: ${breakpoints.medium}px) {
    height: 15px;
  }
  @media (max-width: ${breakpoints.small}px) {
    height: 10px;
  }

  span,
  span:before,
  span:after {
    transition: all 0.4s;
    background-color: #3b566e;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
  }

  span:before,
  span:after {
    transition: all 0.4s;
    background-color: #3b566e;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
    width: 75%;
  }

  span:before,
  span:after {
    content: "";
  }

  span {
    top: 16px;
  }

  span:before {
    transform-origin: 33% 100%;
    top: -10px;
    z-index: 10;
  }

  span:after {
    transform-origin: 33% 0;
    top: 10px;
  }

  span.active,
  span.active:before,
  span.active:after {
    background-color: transparent;
    width: 100%;
  }

  span.active:before {
    transform: translateY(5px) translateX(1px) rotate(45deg);
    background-color: #3b566e;
  }

  span.active:after {
    transform: translateY(-6px) translateX(1px) rotate(-45deg);
    background-color: #3b566e;
  }
`

const NavbarButtons = styled.div`
  height: 100%;
  padding-top: 10px;
  flex-basis: 20%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;

  @media (max-width: ${breakpoints.large}px) {
    position: relative;
    padding-top: 20px;
    padding-left: 40px;
    margin-right: 0;
    justify-content: flex-end;
    flex-basis: 33%;
  }
  @media (max-width: ${breakpoints.medium}px) {
    padding-left: 20px;
  }
  @media (max-width: ${breakpoints.small}px) {
    padding-left: 12px;
  }
`

export default {
  Root,
  Header,
  NavbarConatianer,
  NavbarLinks,
  HamburgerMenuButton,
  NavbarButtons,
}
