import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  
  @media (min-width: ${breakpoints.large}px) {
& h2{
  font-size: 2.5rem;
}
 }
`

export default {
  Root,
}
