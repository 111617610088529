import React from "react"
import PropTypes from "prop-types"
import E from "./Layout.style"
import GlobalStyle from "../GlobalStyle"
import Header from "../Header"
import Footer from "../Footer"
import WhatsAppIcon from "../WhatsAppIcon"

const Layout = ({ children }) => {
  return (
    <E.Root>
      <GlobalStyle />
      <Header />
      {children}
      <WhatsAppIcon />
      <Footer />
      <script src="https://cdn.enable.co.il/licenses/enable-L6387avrw10fk82-0920-15272/init.js"></script>
      <script src="/__/firebase/7.23.0/firebase-app.js"></script>
      {/* <script src="/__/firebase/7.23.0/firebase-analytics.js"></script> */}
      <script src="/__/firebase/init.js"></script>
      <script src="https://cdn.polyfill.io/v2/polyfill.min.js"></script>
    </E.Root>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
