import React from "react"
import E from "./Footer.style"
import Logo from "../Logo"
import Link from "../Link"

const Footer = () => {
  return (
    <E.Root>
      <div className="container">
        <div className="footerWrap">
          <div className="info">
            <Logo className="logo" height="60" />
          </div>

          <div className="links">
            <ul>
              <li>
                <Link to="/tariff">תעריפון</Link>
              </li>
              <li>
                <Link rel={`nofollow`} to="/disclosure">גילוי נאות</Link>
              </li>
              <li>
                <Link to="/servicelevelagreement">אמנת שירות</Link>
              </li>
              <li>
                <Link to="/privacypolicy">מדיניות פרטיות</Link>
              </li>
            </ul>
          </div>

          <div className="address">
            <span>תוצרת הארץ 6 קומה 13, תל אביב</span>
            <span>טלפון: 052-5556227 (972)</span>
            <span>
              <a href="mailto:service@polaris-fin.co.il">
                service@polaris-fin.co.il
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="copyright">
        <a href="https://www.fintek.co.il/" rel="noreferrer" target="_blank">
          2020 פינטק דיגיטל &copy;
        </a>
      </div>
    </E.Root>
  )
}

export default Footer
