import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"

const Root = styled.div`
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 999;
  height: 60px;
  width: 60px;
  transition: 0.1s ease-in;

  svg {
    border: 4px solid #fff;
    border-radius: 50%;
  }

  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  @media (max-width: ${breakpoints.large}px) {
    left: 4px;
    bottom: 45px;
  }
`

export default Root
