import heeboLight from '../../../../assets/fonts/Heebo-Light.ttf';
import heeboRegular from '../../../../assets/fonts/Heebo-Regular.ttf';
import heeboBold from '../../../../assets/fonts/Heebo-Bold.ttf';


export default (`
@font-face {
  font-family: 'heebo';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src:
    local('heebo'),
    url('${heeboRegular}') format('truetype');
}
 
@font-face {
  font-family: 'heebo';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('heebo'),
    url('${heeboLight}') format('truetype');
}
 
@font-face {
  font-family: 'heebo';
  font-style: normal;
  font-display: swap;
  font-weight: bold;
  src:
    local('heebo'),
    url('${heeboBold}') format('truetype');
}  
`)
