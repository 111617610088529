import React, { useState, useEffect } from "react"
import E from "./Header.style"
import Button from "../Button"
import Link from "../Link"
import Logo from "../Logo"
import { navigate } from "gatsby"

const Header = props => {
  const [activeMenu, setActiveMenu] = useState(false)
  const [showNavbarBackground, setShowNavbarBackground] = useState(false)

  const OpenMenu = () => {
    setActiveMenu(!activeMenu)
  }

  const handleClick = () => {
    navigate("https://portal.roeto.co.il/polaris")
  }

  const onScrollFunc = () => {
    let currentScrollPos = window.pageYOffset
    if (currentScrollPos > 0) {
      setShowNavbarBackground(true)
    } else {
      setShowNavbarBackground(false)
    }
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.pageYOffset > 0) {
        setShowNavbarBackground(true)
      }
      if (window.location.pathname === "/") {
        window.addEventListener("scroll", onScrollFunc)
      } else {
        setShowNavbarBackground(true)
      }
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("scroll", onScrollFunc)
      }
    }
  }, [])

  return (
    <E.Root showNavbarBackground={showNavbarBackground}>
      <E.Header showNavbarBackground={showNavbarBackground}>
        <E.NavbarConatianer>
          <Link to="/">
            <Logo height={"100"} className="mob" />
          </Link>

          <E.NavbarLinks className={activeMenu ? null : "unshow"}>
            <li>
              <Link to="/">בית</Link>
            </li>
            <li>
              <Link to="/about">אודות</Link>
            </li>
            <li>
              <Link to="/services">שירותים</Link>
            </li>
            <li>
              <Link to="/contact">צור קשר</Link>
            </li>
            {/* <li>
              <Link
                to="https://portal.roeto.co.il/ILAN/" 
                target="_blank"
                rel="noreferrer"
              >
              מידע אישי ללקוחות רות אילן
              </Link>
            </li> */}
          </E.NavbarLinks>

          <E.HamburgerMenuButton onClick={OpenMenu}>
            <span className={activeMenu ? "active" : ""} />
          </E.HamburgerMenuButton>

          <E.NavbarButtons>
            <Button
              onClick={handleClick}
              // className={showNavbarBackground ? "headerButton" : ""}
              className={"headerButton"}
            >
              מידע אישי
            </Button>
          </E.NavbarButtons>
        </E.NavbarConatianer>
      </E.Header>
    </E.Root>
  )
}

export default Header
