import React from "react"
import logo from "../../../assets/images/logo.png"
import E from "./Logo.style"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

const Logo = ({ height, width, className }) => {
  const logoQuery = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <E.Root
      height={height}
      width={width}
      alt={logoQuery.site.siteMetadata.title}
      src={logo}
      className={className}
    />
  )
}

export default Logo

Logo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
}
