export default {
  primary: {
    main: "#11416b",
    dark: "#0b243a",
    yellow: "#f2be34",
  },
  secondary: {
    main: "#0056b3",
  },
  text: {
    main: "#6F8BA4",
    dark: "#3B566E",
  },
}
