import React from "react"
import E from "./Button.style"
import PropTypes from "prop-types"

const noop = () => {}

const Button = props => {
  const { children, className, isDisabled, onClick, variant, buttonColor, size } = props

  return (
    <E.Button
      className={className}
      disabled={isDisabled}
      onClick={onClick}
      type={variant}
      buttonColor={buttonColor}
      size={size}
    >
      {children}
    </E.Button>
  )
}

Button.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["button", "submit"]),
  buttonColor: PropTypes.oneOf(["dark", "light"]),
  size: PropTypes.oneOf(["small", "medium"]),
}

Button.defaultProps = {
  isDisabled: false,
  onClick: noop,
  variant: "button",
  buttonColor: "light",
  size: "small"
}

export default Button
