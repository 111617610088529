import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ children, to, target, ...other }) => {
  const internal = /^\/(?!\/)/.test(to);

  if (internal && target !== '_blank') {
    return (
      <GatsbyLink to={to} {...other} activeClassName="active">
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} target={target} {...other}>
      {children}
    </a>
  );
};

export default Link;
