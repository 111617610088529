import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"

const Root = styled.img`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  display: block;
  padding: 5px;
  margin-bottom: 5px;

  &.mob {
    @media (max-width: ${breakpoints.small}px) {
      height: 85px;
    }
  }
`

export default {
  Root,
}
