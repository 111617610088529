import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"
import palette from "../GlobalStyle/globals/palette"

const Button = styled.button`
  cursor: pointer;
  color: ${({ buttonColor }) =>
    buttonColor === "dark" ? "#fff" : palette.primary.main};
  background: ${({ buttonColor }) =>
    buttonColor === "dark" ? palette.primary.main : "#fff"};
  list-style: none;
  border: 1px solid #697ba2;
  border-radius: 100px 0px 100px 100px;
  outline: none;
  width: ${({ size }) => (size === "medium" ? "170px" : "90px")};
  height: ${({ size }) => (size === "medium" ? "40px" : "40px")};
  min-width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.2s ease;

  @media (max-width: ${breakpoints.small}px) {
    width: ${({ size }) => (size === "medium" ? "140px" : "40px")};
    height: ${({ size }) => (size === "medium" ? "35px" : "25px")};
    min-width: 80px;
    font-size: 0.8rem;
  }

  &:hover {
    color: ${({ buttonColor }) =>
    buttonColor === "light" ? "#fff" : palette.primary.main};
    background: ${({ buttonColor }) =>
    buttonColor === "light" ? palette.primary.main : "#fff"};
  }
  &::focus {
    border: 1px solid #697ba2;
  }
  &:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    pointer-events: none;
    a {
      color: #f5f5f5;
    }
  }
  &.headerButton {
    color: #fff;
    background: ${palette.primary.yellow};
    height: 50px;
    width: 150px;
    @media (max-width: ${breakpoints.large}px) {
      height: 40px;
      width: 120px;
    }
    @media (min-width: ${breakpoints.large}px) {
      font-size: 1.3rem;
    }
    @media (max-width: ${breakpoints.small}px) {
      height: 40px;
      width: 55px;
    }

    &:hover {
      color: ${palette.primary.main};
      background: #fff;
    }
  }

  &.heroButton {
    width: 200px;
    padding: 0 5px;
  }

  @media (max-width: ${breakpoints.large}px) {
    &.heroButton {
      color: ${palette.primary.main};
    border-radius: 100px ;
      max-width: 250px;
      background: #fff;
      width: 50%;
      &:hover {
        color: #fff;
        background: ${palette.primary.main};
      }
    }
  }
`

export default {
  Button,
}
