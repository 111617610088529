import palette from "../globals/palette"
import breakpoints from "../globals/breakpoints"

export default `
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
 
*, *::before, *::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}  

strong, b {
  font-weight: bold;
}
 
body {
  color: ${palette.text.main};
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: "heebo", Arial;
  font-weight: normal;
  line-height: 1.5;
  background-color: #fff;
}
 
@media print {
  body {
    background-color: #fff;
  }
}
 
body::backdrop {
  background-color: #fff;
}

img,
fieldset {
  border: 0;
}


/* Links */
a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: ${palette.secondary.main};
  text-decoration: underline;
}



/* Quotes */
blockquote,
q {
  color: inherit;
}
blockquote {
  border: none;
  padding-left: 40px;
}
[dir='rtl'] blockquote {
  padding-left: 0;
  padding-right: 40px;
}

blockquote::before,
q::before {
  content: '\\201C';
}

blockquote::after,
q::after {
  content: '\\201D';
}

blockquote::before {
  float: left;
  /* to keep the quotes left of any child elements like blockquote > p */
  margin-left: -1em;
  text-align: right;
  width: 1em;
}
[dir='rtl'] blockquote::before {
  float: right;
  margin-right: -1em;
  text-align: left;
}

blockquote > :last-child {
  display: inline-block; /* so the quotes added via pseudos follow it immediately. */
}


/* Other typographical elements */
p + p {
  margin-top: 14px;
}

small {
  font-size: 0.78em;
  font-style: inherit;
  line-height: 1.45;
  color: #6B778C;
  font-weight: bold;
  margin-top: 16px;
}

code,
kbd {
  font-family: "SFMono-Medium", "SF Mono", "Segoe UI Mono", "Roboto Mono", "Ubuntu Mono", Menlo, Consolas, Courier, monospace;
}

code, pre {
  background: rgba(0,0,0,0.04);
  font-size: 0.8rem;
  padding: 0 4px;
  direction: ltr;
  display: inline-block;
}

var,
address,
dfn,
cite {
  font-style: italic;
}

abbr {
  border-bottom: 1px #ccc dotted;
  cursor: help;
}


/* Table */
table {
  border-collapse: collapse;
  width: 100%;
}

thead,
tbody,
tfoot {
  border-bottom: 2px solid #DFE1E6;
}

td,
th {
  border: none;
  padding: 6px 8px;
  text-align: right;
}

th {
  vertical-align: top;
}

td:first-child,
th:first-child {
  padding-left: 0;
}

td:last-child,
th:last-child {
  padding-right: 0;
}

caption {
  font-size: 1.4em;
  font-style: inherit;
  line-height: 1.2;
  color: #172B4D;
  font-weight: 500;
  margin-top: 28px;
  margin-bottom: 8px;
  text-align: right;
}


/* global style */
section + section {
  padding-top: 40px;
}

section h2 {
  text-align: center;
  font-weight: 300;
  padding: 40px;
}

/* global classes */

#enable-toolbar-trigger {
  top: -18px;
}

section.colored {
  background: #F7FAFD;
}

.container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: ${breakpoints.small}px) {
  .container {
    max-width: ${breakpoints.small - 32}px;
  }
}

@media (min-width: ${breakpoints.medium}px) {
  .container {
    max-width: ${breakpoints.medium - 32}px;
  }
}

@media (min-width: ${breakpoints.large}px) {
  .container {
    max-width: ${breakpoints.large - 32}px;
  }
}

@media (min-width: ${breakpoints.extraLarge}px) {
  .container {
    max-width: ${breakpoints.extraLarge - 32}px;
  }
}
`
