import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"
import palette from "../GlobalStyle/globals/palette"

const Root = styled.footer`
  background-color: #f4f8fb;

  .copyright {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 0.8rem;
    text-align: center;
    letter-spacing: 1px;
    position: relative;

    &:before {
      content: "";
      height: 1px;
      width: 80%;
      background: #e9f0fd;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    a {
      :hover {
        cursor: pointer;
      }
    }
  }

  .footerWrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > div {
      flex-basis: auto;
    }
  }

  .info {
    letter-spacing: 0.88px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
  }

  .links {
    @media (min-width: ${breakpoints.large}px) {
      flex: 1 1 auto;
    }
    
    ul {
      margin-right: 20px;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      flex-basis: auto;
      white-space: nowrap;
      padding-left: 15px;
    }
    
    a {
      font-size: 0.8rem;
    }
  }
  
  .logo {
    @media (max-width: ${breakpoints.large}px) {
      margin-bottom: 20px;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 0;

    @media (max-width: ${breakpoints.medium}px) {
      margin-bottom: 30px;
    }
  }

  h5 {
    font-weight: normal;
    font-size: 1rem;
    color: ${palette.text.dark};
    margin-bottom: 30px;
  }

  ul li {
    line-height: 32px;
  }

  li a {
    font-size: 14px;
    color: #6f8ba4;

    :before {
      content: "";
      border: 1px solid #dae6fd;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 2px;
      transform: rotate(135deg);
      margin-left: 12px;
    }
  }

  a {
    color: ${palette.primary.main};

    &:hover {
      color: ${palette.primary.main};
      text-decoration: none;
    }
  }

  .address {
    font-size: 0.8rem;
    margin-bottom: 20px;
    
    span {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      display: inline-block;
      padding: 0 0 0 10px;
      margin: 0 0 0 10px;
      position: relative;
      
      &:not(:last-child):after {
        content: '·';
        display: inline-block;
        position: absolute;
        font-weight: bold;
        top: 1px;
        left: 0;
      }
    }
    
    @media (min-width: ${breakpoints.large}px) {
      display: flex;
      align-items: center;
      margin-right: auto;
      margin-bottom: 0;
    }
  }
`

export default {
  Root,
}
